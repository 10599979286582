import { PaymentModal } from '@/components/modals/PaymentModal.tsx'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { DialogTitle } from '@/components/ui/dialog.tsx'
import { PaymentModalServiceDetails } from '@/components/PaymentModalServiceDetails.tsx'
import { PaymentModalContactFooter } from '@/components/PaymentModalContactFooter.tsx'
import { PaymentModalPricingSectionContainer } from '@/components/PaymentModalPricingSectionContainer.tsx'

export function PaymentModalContainer() {
  const { isPremiumUser } = useCurrentUserHook()

  return (
    <PaymentModal isOpen={!isPremiumUser}>
      <DialogTitle className={'text-center text-[1.75rem] font-semibold leading-9 text-primary'}>
        Olympe devient payant !
      </DialogTitle>
      <section className={'space-y-6 text-center'}>
        <PaymentModalServiceDetails />
        <PaymentModalPricingSectionContainer />
        <PaymentModalContactFooter />
      </section>
    </PaymentModal>
  )
}
